#mobile-navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100vw;
    box-shadow: 0 2px 15px 5px rgba(0, 0, 0, 0.15);
}

#mobile-navbar-bg {
    height: 5em;
}

#mobile-popup {
    height: calc(100vh - 5em);
    font-weight: 300;
    background-color: #f3f3f3;
    color: #fff;
    padding: 0em;
    overflow: auto;
    border-top: 1px solid #d3d3d3;
}

#mobile-popup[data-displayed="false"] {
    display: none;
}

#mobile-popup div {
    font-size: 1.2em;
}

#mobile-popup-links {
    // margin-top: 2em;
    margin-bottom: 20em;
    // height: 100vh;
    overflow: auto;

    nav {
        font-size: 1.2em;
        padding-left: 1.5em;
    }
}

#mobile-popup-profile {
    position: absolute;
    bottom: 0;
    z-index: 2;
    padding-top: 2em;
    padding-bottom: 1.5em;
    width: 100%;
    background-color: #f3f3f3;
}