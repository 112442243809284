.customer-page{
    .invalid-feedback{
        display: flex;
    }
    .form-errors{
        font-size: .875em;
        color: #dc3545; 
    }
    .search-row{
        .search-field{
            background: #FFFFFF;
            border: 1px solid #E9EBFF;
            box-sizing: border-box;
            border-radius: 40px;
            max-width: 350px;
            width: 100%;
        }
        .search-btn{
            margin-left: 1rem;
            background: #253286;
            border-color: #253286;
            border-radius: 20px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            padding: 8px 25px;
        }
        .search-error{
            color: red;
            font-weight: 700;
            font-style: italic;
        }
    }
    .content-container{
        max-width: 800px;
        width: 100%;
        .field-col{
            width: 48%;
        }  
        .form-label{
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 28px;
            letter-spacing: 0.25px;
            color: #253286;
        } 
    }
    .info-container{

        .select-field{
            div[class*=-control]{
                border: 1px solid #233577;
                border-radius: 3px;
                font-weight: 600;
                font-size: 14px;
                line-height: 28px;
                background: #E9EBFF;
            }
        }

        padding-top: 2rem;
        padding-bottom: 1rem;
        .form-control{
            background: #E9EBFF;
            border: 1px solid #233577;
            box-sizing: border-box;
            border-radius: 3px;
            font-weight: 600;
            font-size: 14px;
            line-height: 28px;
        }
    }
    .form-container{
        background: #FFFFFF;
        border-radius: 20px;
        padding: 2rem;
        .form-control{
            border: 1px solid #233577;
            box-sizing: border-box;
            border-radius: 3px;
            font-weight: 600;
            font-size: 14px;
            line-height: 28px;
        }
        div[class*="-control"]{
            border: 1px solid #233577;
            font-weight: 600;
            font-size: 14px;
        }
        .submit-btn{
            border: 1px solid #233577;
            background: #233577;
            border-radius: 5px;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.25px;
            padding: 0.5rem 3rem;
        }
    }
}


@media (max-width: 600px) {
    .customer-page{
        .field-row{
            flex-direction: column;
            .field-col{
                width: 100%;
            }
        }
        .form-container{
            .submit-btn{
                margin-top: 1rem;
                width: 100%;
            }
        }
    }

}