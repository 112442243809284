.link-containers nav {
  color: #979797;
  font-size: 0.85em;
  font-weight: 600;
  border-left: 5px solid #FFF;
}

#mobile-popup-links .is-active nav,
.link-containers .is-active nav,
.link-containers nav:hover {
  color: #253286;
  background-color: #E9EBFF;
  border-left: 5px solid #0075FF;
}

#main-banner .user-profile-title {
  font-size: 0.9em;
  font-weight: 900;
}

#main-banner .user-profile-name {
  font-size: 0.7em;
  color: #979797;
}

.Template {
  background-color: #E9EBFF;
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  padding-bottom: 5em;
}

#login-page {
  min-height: 30em;
  height: calc(100vh - 60px - 90px);
  width: 100vw;
}

[data-displayed="false"] {
  display: none;
}

.clickable {
  cursor: pointer;
}

.search-box {
  width: 24em;
  position: relative;

  #search-icon {
    position: absolute;
    height: 1.5em;
    left: 0.5em;
    top: 0.45em;
  }

  input {
    width: 100%;
    // padding: 1.5em 2.5em 1.5em 1.5em;
    padding-left: 3em;
    border-color: 1px solid #d7d7d7;

    &::placeholder {
      color: #d7d7d7;
    }
  }
}

#dashboard-cards-table,
.legend-table,
.top-volume-table {
  border-collapse: separate;
  border-spacing: 0 0.7em;
  table-layout: fixed;
  word-wrap: break-word;
}

#dashboard-cards-table tr td:first-child {
  border-radius: 0.5em 0 0 0.5em;
}

#dashboard-cards-table tr td:nth-child(8) {
  border-radius: 0 0.5em 0.5em 0;
}

// Statistics
.pie-container {
  background-color: white;
  display: inline-block;
  border-radius: 2em;
}

.pie {
  position: relative;
  height: var(--radius);
  width: var(--radius);
  border-radius: var(--radius);
  background: conic-gradient(var(--conic-gradient)); // Should add up 100%.
  // box-shadow: 0px 1px 2px 2px #f3f3f3
}

.pie:before {
  --circle-width: calc((var(--radius) - var(--border-thickness)) / 2);
  --offset-value: calc(var(--border-thickness) / 2);
  content: "";
  position: absolute;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  border-radius: var(--circle-width);
  border-width: var(--circle-width);
  border-style: solid;
  top: var(--offset-value);
  left: var(--offset-value);
  // box-shadow: 0px -1px 2px 2px #f3f3f3;
}

.legend-box {
  height: 1.5em;
  width: 1.5em;
  border-radius: 0.5em;
}

.margin-right-1em {
  margin-right: 1em;
}

#statistic-content {
  display: block;
  font-size: 1.3em;

  #mini-information-container {
    display: block;
  }

  .small-information {
    background-color: white;
    border-radius: 1em;

    img {
      height: 3em;
    }

    b {
      font-weight: 600;
      font-size: 1.2em;
    }
  }

  #left-part {
    width: 100%;
  }

  .per-row-2-container,
  .per-row-3-container {
    border-radius: 2em;
    flex-grow: 1;
    flex-basis: 0;
    background-color: white;
  }

  .per-row-2,
  .per-row-3 {
    width: 35%;
    padding: 2em 2em 1em 2em;
    margin-bottom: 1em;
  }

  .top-volume {
    background-color: white;
    border-radius: 2em;
    padding: 2em 2em 1em 2em;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.cholesterol_level{
  text-transform: capitalize;
  font-weight: bold;
  &.desirable{
    color: green;
  }
  &.borderline_high{
    color: #ff9800;
  }
  &.high{
    color: red;
  }
}

.conditional-display{
  margin-left: 1rem;
}

@media (min-width: 992px) {
  #statistic-content {
    font-size: 0.85em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    #mini-information-container {
      display: flex;
    }

    #left-part {
      width: 100%;
    }

    .per-row-2 {
      padding: 2em 2em 1em 2em;
    }

    .per-row-3 {
      padding: 2em 1.5em 1em 1.5em;
    }

    .top-volume {
      margin-top: 2em;
    }
  }
}

@media (min-width: 1100px) {
  #statistic-content {
    font-size: 1em;
  }
}

@media (min-width: 1300px) {
  #statistic-content {
    font-size: 1.15em;
  }
}

@media (min-width: 1500px) {
  #statistic-content {
    display: flex;
    // flex-wrap: nowrap;
    font-size: 0.95em;
    width: 100%;

    #left-part {
      width: 100%;
      flex-shrink: 0;
    }

    .top-volume {
      width: 100%;
      flex-shrink: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.conditional-display[displayed="false"] {
  display: none;
}