.footer{
  &.end-campaign{
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}

.caring-stores{
  padding-bottom: 50px;
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  .state-select{
    margin-bottom: 2rem;
  }
  .store-container{
    margin-bottom: 1rem;
  }
  h1,h2{
    font-weight: 900;
  }
  .end-note{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 27px;
    font-weight: 600;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .footer{
    .left{
      margin-top: 0 !important;
    }
    .right{ 
      display: none;
    }
  }
  .caring-stores{
    h1{
      font-size: 23px;
    }
    h2{
      font-size: 19px;
    }
  }
}