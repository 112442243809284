.header{
    max-width: 2880px;
    margin: auto;
    height: 90px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    flex-direction: row;
    flex-wrap: nowrap;
    .left{
        height: 100%;
        img{
            padding: 0px 7px;
        }
    }
    img{
        height: 100%;
    }
    .right{
        height: 100%;
        display: flex;
        justify-content: end;
        text-align: right;
    }
}

.footer{
    max-width: 2880px;
    margin: auto;
    height: 60px;
    background: #272D6A;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    a{
        color: white;
    }
    .right{
        text-align: right;
        display: flex;
        div{
            &:not(:last-child) {
                border-right: 1px solid #fff;
                padding-right: 20px;
                margin-right: 16px;
            }
        }
    }
    &.end-campaign{
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}

@media (max-width: 820px) {
    .footer{
        font-size: 11px;
    }
}

@media (max-width: 768px) {
    .header{
        .left, .right{
            display: flex;
            align-items: center;
        }
        .right{
            a{
                height: 60%;
                img{
                    height: 100%;
                }
            }
        }
        .left{
            img{
                height: 60%;
            }
        }
    }
    .footer{
        height: auto;
        flex-direction: column-reverse;
        justify-content: center;
        padding: 1rem 1rem;
        font-size: 12px;
        .left{
            margin-top: 1rem;
        }
        .right{ 
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
            line-height: 24px;
            div{
                &:first-child {
                    border-right: none;
                    width: 100%;
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }
    }
}
@media (max-width: 500px) {
    .header{
        height: 70px;
        padding: 0.5rem;
    }
}