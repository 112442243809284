#main-banner {
    position: fixed;
    top: 0;
    z-index: 200;
    height: 90px;
    width: 100%;
    box-shadow: 0 2px 15px 5px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
}

#main-banner .banner-icon {
    height: 3em;
}

#main-banner .banner-icon:hover {
    cursor: pointer;
    background-color: #f3f3f3;
    border-radius: 50%;
}

#profile-popup {
    font-weight: 300;
    border-radius: .75em;
    width: 30em;
    background-color: #FFF;
    color: #fff;
    font-size: .9em;
    padding: 1em 1.25em;
    position: absolute;
    right: 5em;
    top: 8em;
    // display: none;
    box-shadow: 0 1em 3em rgba(0,0,0,.175)!important;
    z-index: 1; /* In front of select2 fields */
}

#profile-popup:after {
    content: '';
    position: absolute;
    top: 0.4em;
    right: 1.5em;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #FFF;
    margin-top: -20px;
}

#profile-popup[data-displayed="false"] {
    display: none;
}