#desktop-sidebar {
  width: 14.5em;
  box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 201;
  top: 0;
  height: 100%;
  background-color: white;

  .menu-logo-div{
    padding: 1.5rem;
    border-bottom: 1px solid #D7D7D7;

    .menu-logo{
      width: 100%;
    }
  }
}

.contact-us-info {
  position: absolute;
  bottom: 0;
}